import { Box } from 'stylewhere/components'
import { OperationConfig } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'

const checkStatus = (status, operation, type) => {
  let list: any = []
  if (operation && operation.itemStatuses && operation.itemStatuses[type]) list = operation.itemStatuses[type]
  let found = false
  let i = 0
  while (i < list.length && !found) {
    found = list[i].trim() === status.trim()
    i++
  }
  return found
}

export const StatusChip = ({
  status,
  index,
  operation,
  mt,
  customStatusType,
}: {
  status: string
  index?: number
  operation?: OperationConfig
  mt?: number
  customStatusType?: string
}) => {
  const chipStyle = {
    warning: { backgroundColor: '#FFEFC6', color: '#D6A52A' },
    error: { backgroundColor: '#FF8474', color: '#e0402b' },
    default: { backgroundColor: '#DAE6ED', color: '#5E5E5E' },
  }
  let statusType = 'default'
  if (customStatusType) statusType = customStatusType
  else {
    if (checkStatus(status, operation, 'warning')) statusType = 'warning'
    if (checkStatus(status, operation, 'error')) statusType = 'error'
  }
  //if (operation?.itemStatuses.warning.includes(status)) statusType = 'warning'
  //if (operation?.itemStatuses.error.includes(status)) statusType = 'error'

  const text = T.statuses[status] ? __(T.statuses[status]) : status.replaceAll('_', ' ')
  return (
    <Box
      key={index}
      mt={mt || 0}
      mr={4}
      mb={4}
      pv={5}
      ph={8}
      style={{ ...chipStyle[statusType], width: 'fit-content', borderRadius: 5 }}
    >
      <b>{text}</b>
    </Box>
  )
}
