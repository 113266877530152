/* eslint-disable max-classes-per-file */
import { Component } from 'react'
import {
  TmrItem,
  ProductInfoField,
  TmrFullItemEvent,
  AttributeMappings,
  TmrSimpleAttributesMap,
  ItemEvents,
  Items,
} from 'stylewhere/api'
import { Spinner } from '@chakra-ui/react'
import { Box, Card, IdentifierRow, Modal, ProductInfoBox, Spacer, ItemEventBox } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  onClose: () => void
  item?: TmrItem
  itemId?: string
  visible: boolean
}

interface State {
  item?: TmrItem
  events?: TmrFullItemEvent[]
  fields?: ProductInfoField[]
  attributes?: TmrSimpleAttributesMap[]
  loader: boolean
  viewProductAttribute: boolean
}

export class ItemInfoModal extends Component<Props, State> {
  state: State = {
    item: undefined,
    events: undefined,
    fields: undefined,
    attributes: undefined,
    loader: true,
    viewProductAttribute: false,
  }

  productFields: ProductInfoField[] = [
    { path: 'attributes.barcodeBC.value', label: __(T.misc.barcode_bc) },
    { path: 'color.value', label: __(T.misc.color) },
    { path: 'material.value', label: __(T.misc.material) },
    { path: 'size.value', label: __(T.misc.size) },
    { path: 'style.value', label: __(T.misc.style) },
  ]

  async componentDidMount() {
    this.init()
  }

  init = async () => {
    const { item, itemId } = this.props

    let ret
    if (item) {
      ret = item
    } else if (itemId) {
      ret = await Items.get<TmrItem>(itemId).then((res) => res)
    }
    if (ret && ret.id) {
      const cols: ProductInfoField[] = await AttributeMappings.getProductAttribute(ret.product, this.productFields, [
        'barcodeBC',
      ])
      const attrs: TmrSimpleAttributesMap[] = await AttributeMappings.getItemAttribute(ret)
      let events: any = { content: [] }
      try {
        events = await ItemEvents.get(ret.id, {
          size: 100,
          sort: 'creationDate,DESC',
        })
      } catch (e) {}
      this.setState({ fields: cols, attributes: attrs, item: ret, events: events ? events.content : [], loader: false })
    }
  }

  getProductFields = () => {
    const { fields, viewProductAttribute } = this.state
    if (viewProductAttribute) {
      return fields || this.productFields
    }
    return this.productFields
  }

  managerViewProductAttribute = () => {
    this.setState({ viewProductAttribute: !this.state.viewProductAttribute })
  }

  render() {
    const { visible, onClose } = this.props
    const { attributes, events, loader, viewProductAttribute, item } = this.state
    return (
      <Modal size="6xl" onClose={onClose} visible={visible} fullContent>
        <Box flex row>
          <Box flex={3} ml={35}>
            <Modal.Title style={{ flex: 0, paddingTop: 20, paddingBottom: 15 }}>{__(T.misc.item_info)}</Modal.Title>
            <Box
              flex
              ph={35}
              pb={0}
              pr={0}
              pl={0}
              mr={35}
              mb={20}
              style={{
                height: 'calc(80vh - 40px)',
                maxHeight: 'calc(80vh - 40px)',
                overflow: 'auto',
              }}
            >
              {!loader && item && (
                <>
                  <ProductInfoBox
                    product={item.product}
                    fields={this.getProductFields()}
                    showMore={!loader ? () => this.managerViewProductAttribute() : undefined}
                    viewMore={viewProductAttribute}
                    paddingCard={'0px 15px 0px 0px'}
                    shadowCard={'unset'}
                  />
                  <Spacer />
                  <Card mb={10} flex title="Item">
                    {attributes &&
                      attributes.map((attribute) => (
                        <ProductInfoBox.Field label={attribute.label} value={attribute.value} />
                      ))}
                  </Card>
                  <Card title="Identifiers">
                    {item.identifiers?.map((identifier, index) => (
                      <IdentifierRow identifier={identifier} last={index === item.identifiers.length - 1} />
                    ))}
                  </Card>
                </>
              )}
              {loader && (
                <Box mt={20} center>
                  <Spinner thickness="5px" speed="0.65s" color="#000" size="xl" />
                </Box>
              )}
            </Box>
          </Box>
          <ItemEventBox events={events} />
        </Box>
      </Modal>
    )
  }
}
