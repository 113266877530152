import { Component } from 'react'
import { Page, GroupedItemList, OperationReadingList, TagInfoBox } from 'stylewhere/components'
import { DecodedItem, Items, TagInfoItem } from 'stylewhere/api'
import { Router, RemoteOperation, RfidReader, OperationReadingProps, OperationReadingProvider } from 'stylewhere/shared'
import { TagInfoOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { TagInfoExtensions } from 'stylewhere/extensions'

interface State {
  items: DecodedItem<string>[]
}

export default class TagInfo extends Component<OperationReadingProps<State>, State> {
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<TagInfoOperationConfig>(Router.getMatchParams(this.props).opCode)

  state: State = {
    items: [],
  }

  componentDidMount() {
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode, true)
  }

  getDecodeRequest = () => {
    return {
      url: TagInfoItem.batchValidateEndpoint(),
      payload: {
        operationId: this.operation.id,
        fetchReplaceIdentifier: false,
      },
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setOnDecodedItemCallback(this.onItemDecoded, this.getDecodeRequest())
  }

  onItemDecoded = (decodedItems: { [epc: string]: DecodedItem<string> }) => {
    const { items } = this.state
    Object.keys(decodedItems).forEach((key) => {
      const decodedItem = decodedItems[key]
      const item = (decodedItem.item || decodedItem) as any
      const check = items.find((itm) => itm.item !== null && itm.item?.id === item.id)
      if (!check) {
        items.push({
          item: item,
          identifierCode: key,
        })
      }
    })
    this.setState({ items })
  }

  clear = () => this.setState({ items: [] })

  goBack = () => {
    Router.navigate('/')
  }

  render() {
    const { items } = this.state
    return (
      <Page title={this.operation.description} enableEmulation>
        <Page.Sidebar>
          <TagInfoBox
            decodeRequest={this.getDecodeRequest()}
            onItemDecoded={this.onItemDecoded}
            items={items}
            clear={this.clear}
          />
        </Page.Sidebar>
        <Page.Content notBoxed>
          <GroupedItemList rows={items} />
          {/* <OperationReadingList extension={TagInfoExtensions} items={items} operation={this.operation} /> */}
        </Page.Content>
      </Page>
    )
  }
}
