import React, { Component } from 'react'
import { DecodedItem, DecodedParcel, Parcels, ShippingParcel, SimpleParcelList } from 'stylewhere/api'
import {
  AntennaButton,
  Box,
  Button,
  DecodedItemsModal,
  GroupedParcelList,
  Modal,
  Page,
  SmallTextCounter,
  Spacer,
  TagCounter,
} from 'stylewhere/components'
import { SimpleParcelListExtensions } from 'stylewhere/extensions'
import { T, __ } from 'stylewhere/i18n'
import {
  AppStore,
  getDataFromSchema,
  OperationReadingProps,
  OperationReadingProvider,
  OperationReadingState,
  RemoteOperation,
  RfidReader,
  Router,
  SimpleParcelListOperationConfig,
} from 'stylewhere/shared'
import {
  closeModal,
  openModal,
  showToast,
  showToastError,
  askUserConfirmation,
  isModalError,
} from 'stylewhere/shared/utils'

interface State extends OperationReadingState {
  parcels: ShippingParcel[]
  identifierCodesByParcel: { [parcelId: string]: string[] }
  unexpectedDecodedParcels: { [epc: string]: DecodedParcel }
  ignoredEpcs: any[]
  confirming: boolean
}

//export default class SimpleParcelListReading extends Component<OperationReadingProps<State>, State> {
export default class SimpleParcelListReading<P extends OperationReadingProps<State>, S extends State> extends Component<
  P,
  S
> {
  antennaRef
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<SimpleParcelListOperationConfig>(this.matchParams.opCode)
  formSchema = SimpleParcelListExtensions.formSchema(this.operation)
  isModal = false

  state: S = {
    items: [],
    loading: true,
    formData: this.locationState.formData ?? {},
    parcels: [],
    identifierCodesByParcel: {},
    unexpectedDecodedParcels: {},
    ignoredEpcs: [],
    confirming: false,
  } as any

  constructor(props) {
    super(props)
    this.antennaRef = React.createRef()
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode)
    this.setState({ parcels: JSON.parse(JSON.stringify(this.locationState.parcels)) ?? [] })
  }

  setRfidReaderDecode = () => {
    RfidReader.setDecodeFunction(this.decodeFunction)
  }

  decodeFunction = async (identifierCodes: string[]) => {
    const { ignoredEpcs } = this.state
    const decodePayload = getDataFromSchema(this.state.formData, this.formSchema)
    const result = await Parcels.decode({
      /** @todo Ripristinare operationId quando il BE supporterà le extensions per la SPL */
      // operationId: this.operation.id,
      identifierCodes,
      ...decodePayload,
    })
    const { parcels, identifierCodesByParcel } = this.state

    Object.keys(result).map(async (tagCode) => {
      const decodedParcel = result[tagCode]
      if (!decodedParcel.item || !decodedParcel.parcelCode) {
        ignoredEpcs.push(tagCode)
      } else {
        const parcel = parcels.find((p) => p.code === decodedParcel.parcelCode)
        if (!parcel) {
          if (decodedParcel.item !== null) {
            const tmp: any = {
              id: decodedParcel.parcelCode,
              code: decodedParcel.parcelCode,
              parcelEntryQuantity: 1,
              __detected: true,
              __quantityRead: 1,
            }
            parcels.push(tmp)
            identifierCodesByParcel[tmp.id] = identifierCodes
          }
        } else if (parcel && decodedParcel.item !== null) {
          parcel.__detected = true
          parcel.__quantityRead = (parcel.__quantityRead ?? 0) + 1
          if (identifierCodesByParcel[parcel.id]) {
            const tmp: any[] = identifierCodesByParcel[parcel.id]
            identifierCodes.map((identifier) => tmp.push(identifier))
            identifierCodesByParcel[parcel.id] = tmp
          } else identifierCodesByParcel[parcel.id] = identifierCodes
        }
      }
    })
    this.setState({ parcels, identifierCodesByParcel, ignoredEpcs })
  }

  removeItem = (decodedItem: DecodedItem) => {
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  getDetected() {
    return this.state.parcels.filter(({ __detected }) => __detected)
  }

  getUnexpected() {
    return this.state.parcels.filter(({ __unexpected }) => __unexpected)
  }

  onConfirm = async () => {
    this.setState({ confirming: true }, this._confirm)
  }

  _confirm = async () => {
    const { parcels, formData } = this.state
    try {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      const confirmData = getDataFromSchema(formData, this.formSchema)
      await SimpleParcelListExtensions.beforeConfirm(this.operation, confirmData, parcels)
      const confirmResult = await SimpleParcelList.save({
        ...confirmData,
        operationId: this.operation.id,
        operationPlaceId: AppStore.defaultWorkstation!.placeId,
        parcelCodes: this.getDetected().map(({ code }) => code),
      })
      await SimpleParcelListExtensions.afterConfirm(this.operation, confirmData, confirmResult)
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.generic_success, { code: this.operation.description }),
        status: 'success',
      })
      this.setState({ confirming: false })
      if (this.operation.postConfirmAction === 'disabled') {
        this.goBack()
      } else {
        if (
          await askUserConfirmation(
            __(T.confirm.post_confirm_action_title),
            this.operation.postConfirmAction === 'keepInput'
              ? __(T.confirm.post_confirm_action_keep_input)
              : __(T.confirm.post_confirm_action_change_input),
            __(T.misc.no),
            __(T.misc.yes)
          )
        ) {
          if (this.operation.postConfirmAction === 'keepInput') {
            this.onClear()
          } else {
            this.goBack()
          }
        } else {
          this.goDashboard()
        }
      }
    } catch (err) {
      this.setState({ confirming: false })
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  goDashboard = () => {
    Router.navigate('/')
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/simple-parcel-list/:opCode', { opCode: this.operation.code })
    } else {
      this.goDashboard()
    }
  }

  onClear = async () => {
    RfidReader.clear()
    this.setState({
      items: [],
      ignoredEpcs: [],
      identifierCodesByParcel: {},
      parcels: JSON.parse(JSON.stringify(this.locationState.parcels)) ?? [],
      unexpectedDecodedParcels: {},
    })
    if (this.locationState.tags && this.locationState.tags.length > 0) {
      RfidReader.emulateTags(this.locationState.tags?.map((tag) => tag.epc) ?? [])
    }
  }

  /** La conferma è abilitata se c'è almeno un detected e nessun unexpected */
  showConfirmButton() {
    const { confirming } = this.state
    if (this.getDetected().length > 0 && this.getUnexpected().length === 0)
      return <Button loading={confirming} disabled={confirming} title={__(T.misc.confirm)} onClick={this.onConfirm} />
    return null
  }

  removeParcel(parcel: ShippingParcel) {
    try {
      const { parcels, identifierCodesByParcel } = this.state
      const idx = parcels.findIndex(({ id }) => id === parcel.id)
      if (idx === -1) throw new Error('Invalid parcel to remove')
      parcels[idx].__detected = false
      parcels[idx].__forced = false
      RfidReader.removeTags(identifierCodesByParcel[parcel.id])
      delete identifierCodesByParcel[parcel.id]
      this.setState({ parcels, identifierCodesByParcel })
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  openIgnoredEpcs = () => {
    const { ignoredEpcs } = this.state
    openModal({
      id: 'ignoredEpcs',
      modal: (
        <DecodedItemsModal
          decodedItems={ignoredEpcs.map((epc) => ({ identifierCode: epc }))}
          operation={this.operation}
          isOpen
          onClose={() => closeModal('ignoredEpcs')}
          title="Epcs"
        />
      ),
    })
  }

  openUnexpectedParcel = () => {
    const { unexpectedDecodedParcels } = this.state
    const parcels: ShippingParcel[] = []
    Object.keys(unexpectedDecodedParcels).forEach((key) => {
      const parcelCode = unexpectedDecodedParcels[key].parcelCode ?? 'UNKNOWN'
      const index = parcels.findIndex((p) => p.code === parcelCode)
      if (index >= 0) {
        parcels[index].parcelEntryQuantity++
      } else {
        parcels.push({
          code: parcelCode,
          parcelEntryQuantity: 1,
          attributes: {},
        } as any)
      }
    })
    openModal({
      id: 'unexpectedParcelsModal',
      modal: (
        <Modal
          title={__(T.misc.unexpected_parcel)}
          visible
          onClose={() => closeModal('unexpectedParcelsModal')}
          size="2xl"
          isCentered
        >
          <Box height="70vh">
            <GroupedParcelList operation={this.operation} parcels={parcels} />
          </Box>
        </Modal>
      ),
    })
  }

  render() {
    const { formData, loading, parcels, unexpectedDecodedParcels, ignoredEpcs } = this.state
    const unexpectedCount = Object.keys(unexpectedDecodedParcels).length
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
            // setFormData: async (fd) => {
            //   if (!(await SimpleParcelListExtensions.formDataIsValid(fd, this.operation, this.formSchema))) return
            //   this.setState({ formData: fd })
            //   this.setRfidReaderDecode()
            // },
          },
        }}
        enableEmulation
      >
        <Page.Sidebar>
          <Box flex style={{ overflowY: 'auto' }}>
            <TagCounter detected={this.getDetected().length} />
            <AntennaButton
              ref={this.antennaRef}
              onClear={this.onClear}
              decodeFunction={this.decodeFunction}
              hideClear={
                unexpectedCount === 0 &&
                this.getDetected().length === 0 &&
                ignoredEpcs.length === 0 &&
                parcels.filter((p) => p.__detected || p.__forced).length === 0
              }
            />
            <Spacer />

            {/* <OperationReadingCounters items={items} /> */}
            <Box row justify="space-evenly">
              {unexpectedCount > 0 && (
                <SmallTextCounter
                  onClick={this.openUnexpectedParcel}
                  status="warning"
                  title="Unexpected"
                  counter={unexpectedCount}
                />
              )}
              {unexpectedCount > 0 && ignoredEpcs.length > 0 && <Spacer />}
              {ignoredEpcs.length > 0 && (
                <SmallTextCounter
                  onClick={this.openIgnoredEpcs}
                  status="ignored"
                  title={__(T.misc.ignored)}
                  counter={ignoredEpcs.length}
                />
              )}
            </Box>
          </Box>
          {this.showConfirmButton()}
        </Page.Sidebar>
        <Page.Content notBoxed>
          <GroupedParcelList
            operation={this.operation}
            parcels={parcels}
            removeParcel={(parcel) => this.removeParcel(parcel)}
            refresh={() => this.forceUpdate()}
          />
        </Page.Content>
      </Page>
    )
  }
}
