import { Component } from 'react'
import styled from '@emotion/styled'
import { Icons, Box, Button, StatusChip, ItemInfoModal, Image, RoundedLabel } from 'stylewhere/components'
import { GroupedItemRowProps, Products } from 'stylewhere/api'
import { MotionStyle } from 'framer-motion'
import { closeModal, openModal } from 'stylewhere/shared/utils'
import { T, __ } from 'stylewhere/i18n'
import { AppStore } from 'stylewhere/shared'

interface State {
  modalVisible: boolean
}

export class GroupedItemRow extends Component<GroupedItemRowProps, State> {
  static Skeleton = styled(Box)``

  roundedLabel = (label: string, value?: string, style?: MotionStyle) => (
    <Box
      row
      mr={15}
      style={{
        backgroundColor: '#EDEDED',
        borderRadius: 5,
        ...style,
      }}
      pv={5}
      ph={10}
    >
      <SmallLabel>{label.toUpperCase()}</SmallLabel>
      <SmallValue>{value ?? ' ---'}</SmallValue>
    </Box>
  )

  getUnknownItemRowCode = () => {
    const { decodedItem } = this.props
    if (decodedItem.identifierCode) return decodedItem.identifierCode
    if (decodedItem.epc) return decodedItem.epc
    return 'Identifier N/A'
  }

  renderUnknownItemRow = () => {
    const { decodedItem, onRemoveItem, mb, unknownHeight } = this.props
    return (
      <>
        <Row row mb={mb} height={unknownHeight || 120} center>
          <Box flex ph={16}>
            <ReadIdentifierCode>{this.getUnknownItemRowCode()}</ReadIdentifierCode>
          </Box>
          {!!onRemoveItem && (
            <Box m={8}>
              <Button onClick={() => onRemoveItem(decodedItem)} variant="secondary" size="small" circle>
                <Icons.Delete width={24} height={24} />
              </Button>
            </Box>
          )}
        </Row>
      </>
    )
  }

  showItemModal = () => {
    const { decodedItem } = this.props
    if (decodedItem.item && !decodedItem.item.fakeItemID) {
      openModal({
        id: 'item-modal',
        modal: <ItemInfoModal visible itemId={decodedItem.item.id} onClose={() => closeModal('item-modal')} />,
      })
    }
  }

  isWarningZone = () => {
    const { operation, itemZoneBase, decodedItem } = this.props
    if (operation && operation.enabledCheckItemZone && itemZoneBase && decodedItem.item && decodedItem.item.zone) {
      return decodedItem.item.zone.id !== itemZoneBase
    }
    return false
  }

  getCode = () => {
    const { configuration, decodedItem } = this.props
    if (configuration && configuration.useProductCode && decodedItem && decodedItem.item && decodedItem.item.product) {
      return decodedItem.item.product.code || undefined
    }
    return decodedItem.identifierCode || undefined
  }

  getIdentifiers = () => {
    const { configuration, decodedItem } = this.props
    if (configuration && configuration.hideIdentifiers) {
      return []
    } else {
      let identifiers = decodedItem.item && decodedItem.item.identifiers ? decodedItem.item.identifiers : []
      if (!configuration || !configuration.useProductCode) {
        identifiers = identifiers.filter((idf) => idf.code !== decodedItem.identifierCode)
      }
      return identifiers
    }
  }

  renderItemRow = () => {
    const { decodedItem, onActionItem, hideProductInfo, mb, configuration, operation, disabledCounter } = this.props
    const { product } = decodedItem.item!
    const showDescription = product.code || product.description
    const identifiers = this.getIdentifiers()
    const code = this.getCode()
    const showIdentifier = code || identifiers.length > 0

    const read = decodedItem.detected ?? 0 // + (unexpected ?? 0)
    const hasChecklist = operation && 'hasChecklist' in operation && operation.hasChecklist
    const counterValue = decodedItem.expected ? `${read}/${decodedItem.expected}` : `${read}${hasChecklist ? '/0' : ''}`

    return (
      <Row row mb={mb} onClick={this.showItemModal} p={15}>
        {!hideProductInfo && product.code && AppStore.getShowProductImage() ? (
          <Box center>
            <Image src={Products.getImageUrl(product)} width={100} height={100} />
          </Box>
        ) : (
          <Box center ml={15} mr={10}>
            <Icons.Rfid style={{ width: 50 }} />
          </Box>
        )}
        <Box flex justify={'center'} mr={0} ml={15}>
          {showIdentifier && (
            /*<Box>
              {code && <ReadIdentifierCode>{code}</ReadIdentifierCode>}
              {identifiers.map((identifier) => (
                <IdentifierCode>
                  {identifier.role?.toUpperCase()}: {identifier.code}
                </IdentifierCode>
              ))}
            </Box>*/
            <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
              {code && <RoundedLabel value={code} style={{ backgroundColor: '#333', color: '#FFF' }} />}
              {identifiers.map((identifier) => (
                <RoundedLabel
                  style={{ backgroundColor: '#333', color: '#FFF' }}
                  label={identifier.role?.toUpperCase()}
                  value={identifier.code}
                />
              ))}
            </Box>
          )}
          {!hideProductInfo && !!showDescription && (
            <Box /*bgGrey*/ pv={0} ph={0} mt={showIdentifier ? 8 : 0} mb={2} style={{ borderRadius: 5 }}>
              {(!configuration || !configuration.useProductCode) && <b style={{ fontSize: 23 }}>{product.code}</b>}
              <span style={{ fontSize: 16 }}>{product.description}</span>
              <Box row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                <RoundedLabel label={__(T.misc.size)} value={product.size?.value || 'n/a'} />
                <RoundedLabel label={__(T.misc.color)} value={product.color?.value || 'n/a'} />
              </Box>
            </Box>
          )}

          {decodedItem.item && decodedItem.item.zone && decodedItem.item.zone.place && (
            <StatusChip status={`${decodedItem.item?.zone?.place.code} - ${decodedItem.item?.zone?.code}`} mt={8} />
          )}
          {decodedItem.statuses && decodedItem.statuses.length > 0 && (
            <Box row>
              {decodedItem.statuses?.map((status, index) => (
                <StatusChip status={status} index={index} />
              ))}
            </Box>
          )}
          {this.isWarningZone() && <StatusChip customStatusType="warning" status={__(T.misc.warning_zone_items)} />}
        </Box>
        {hasChecklist && !disabledCounter && (
          <Box center ml={15}>
            <SmallCounter
              mr={0}
              ph={20}
              detected={read}
              expected={decodedItem.expected ?? 0}
              unexpected={decodedItem.unexpected ?? 0}
              noChecklist={!hasChecklist}
            >
              {counterValue}
            </SmallCounter>
          </Box>
        )}
        {!!onActionItem && (
          <Box center mr={10} onClick={() => onActionItem(this.props.decodedItem)}>
            <Icons.Confirmed style={{ width: 50 }} />
          </Box>
        )}
      </Row>
    )
  }

  render() {
    const { decodedItem } = this.props
    if (!decodedItem.item?.id) return this.renderUnknownItemRow()

    return this.renderItemRow()
  }
}

const Row = styled(Box)<{ mb? }>`
  margin-bottom: ${({ mb }) => (mb ? mb + 'px' : '20px')};
  border: 2px solid #edecec;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background2};
`

const ReadIdentifierCode = styled.label`
  font-weight: bold;
  font-size: 18pt;
`
const IdentifierCode = styled.label`
  font-size: 16pt;
`
const SmallValue = styled.label`
  font-weight: bold;
  font-size: 12pt;
  white-space: nowrap;
`
const SmallLabel = styled.label`
  font-size: 12pt;
  white-space: nowrap;
  margin-right: 5px;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
  noChecklist?: boolean
}>`
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  min-width: 100px;
  width: 100%;
  align-self: center;
  background-color: ${({ detected = 0, expected = 0, unexpected = 0, noChecklist = false }) => {
    if (noChecklist) return '#F0F0F0'
    switch (status) {
      case 'error':
        return '#FF8474'
      case 'warning':
        return '#FFEA67'
      case 'ignored':
        return '#DAE6ED'
      default:
        if (detected + unexpected > expected) return '#F2B57D'
        if (noChecklist) return '#75EBA8'
        if (unexpected > 0) return '#F2B57D'
        if (detected < expected) return '#EDEDED'
        if (detected === expected) return '#75EBA8'
        return '#F0F0F0'
    }
  }};
  border-radius: 10px;
`
