import { create } from 'apisauce'
import {
  ShippingParcelUpdateRequest,
  ShippingParcelCreateRequest,
  ShippingParcel,
  responseErrorCheck,
  api,
  TmrBaseResource,
} from 'stylewhere/api'

import { AppStore, OperationConfig, ShippingOperationConfig } from 'stylewhere/shared'

export class Shippings extends TmrBaseResource {
  static endpoint = 'api/v1/operations' //logistics

  static getOperationEndpoint(operation: ShippingOperationConfig) {
    if (operation.type === 'INBOUND') return `${this.endpoint}/inbound`
    if (operation.type === 'OUTBOUND') return `${this.endpoint}/outbound`
    if (operation.type === 'PACKING') return `${this.endpoint}/packing`
    return ''
  }

  // eslint-disable-next-line consistent-return
  static batchValidateEndpoint(operation: ShippingOperationConfig) {
    return `${this.getOperationEndpoint(operation)}/batchValidate`
  }

  static startParcel(operation: ShippingOperationConfig, data: ShippingParcelCreateRequest) {
    if (operation.type === 'INBOUND' && !data.asn?.destinationPlaceId) {
      data.asn = {
        ...data.asn,
        destinationPlaceId: AppStore.defaultWorkstation?.placeId,
      } as any
    }
    if (operation.type === 'OUTBOUND' && !data.asn?.originPlaceId) {
      data.asn = {
        ...data.asn,
        originPlaceId: AppStore.defaultWorkstation?.placeId,
      } as any
    }
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/startParcel`, {
        ...data,
        operationId: operation.id,
      })
      .then(responseErrorCheck)
  }

  static updateParcel(operation: ShippingOperationConfig, data: ShippingParcelUpdateRequest) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/updateParcelContent`, {
        ...data,
        operationId: operation.id,
      })
      .then(responseErrorCheck)
  }

  static confirmParcel(
    operation: ShippingOperationConfig,
    data: ShippingParcelCreateRequest,
    force = false,
    async = false
  ) {
    let path = ''
    if (force) path = '/force'
    else if (async) path = '/asyncExecution'
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/confirmParcel${path}`, {
        ...data,
        operationId: operation.id,
      })
      .then(responseErrorCheck)
  }

  static parcelByIdentifier(operation: ShippingOperationConfig, identifier: string) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/parcelByIdentifier`, {
        operationId: operation.id,
        identifierCode: identifier,
      })
      .then(responseErrorCheck)
  }

  static parcelByChecklist(operation: ShippingOperationConfig, checklistId: string) {
    return api
      .get(`${this.getOperationEndpoint(operation)}/parcelVersionList`, {
        operationId: operation.id,
        checklistId,
      })
      .then(responseErrorCheck)
  }

  static startChecklist(operation: OperationConfig, data: string, fieldName: string) {
    return api
      .post<any>(`${this.endpoint}/packing/${operation.id}/startChecklist`, {
        [fieldName]: data,
      })
      .then(responseErrorCheck)
  }

  static closeParcel(operation: ShippingOperationConfig, checklistId: string) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/confirmChecklist`, { checklistId })
      .then(responseErrorCheck)
  }
  static print(parcelCode: string, pickingListAttributes: any, endpoint: string) {
    const dataToPrint =
      '' +
      '^XA' +
      '^FWR' +
      '^MD00' +
      '^PR2' +
      '^LH0048,0024' +
      '^BY4^FS' +
      '^FO1050,0070^AG,120,80^FD' +
      pickingListAttributes.CountryShipTo +
      '^FS' +
      '^FO1000,0070^AG,60,40^FD' +
      pickingListAttributes.Season +
      ' ' +
      pickingListAttributes.DocumentOrderInvoice +
      ' ' +
      pickingListAttributes.MarketingLine +
      '^FS' +
      '^FO0840,0070^AG,95,65^FD' +
      pickingListAttributes.AddressNumber +
      '^FS' +
      '^FO0810,0070^AC,36,20^FDBC             ^FS' +
      '^FO0670,0070^AG,60,40^FDCARTON NUMBER^FS' +
      '^FO0350,0070^AG,95,65^FD' +
      (parcelCode.startsWith('999') ? parcelCode.substr(3) : parcelCode) +
      '^FS' +
      '^FO0520,0070^BC,140,N,N,N^FD' +
      (parcelCode.startsWith('999') ? parcelCode.substr(3) : parcelCode) +
      '^FS' +
      '^FO0670,1200^AG,60,40^FDTMR WHS^FS' +
      '^FO0350,1200^AG,95,65^FD' +
      parcelCode +
      '^FS' +
      '^FO0520,1200^BC,140,N,N,N^FD' +
      parcelCode +
      '^FS' +
      '^FO0250,0070^AG,60,40^FD' +
      pickingListAttributes.NameAlpha +
      '^FS' +
      '^FO0150,0070^AG,60,40^FD' +
      pickingListAttributes.MailingName +
      '^FS' +
      '^FO0050,0070^AG,60,40^FD' +
      pickingListAttributes.AddressNumberShipTo +
      ' - ' +
      pickingListAttributes.CityStateShipTo +
      '^FS' +
      '^XZ'
    const printerEndpoint = create({
      baseURL: 'http://' + endpoint,
      timeout: 3000,
    })
    printerEndpoint.setHeaders({
      Accept: 'application/x-www-form-urlencoded',
      'Content-Type': 'application/x-www-form-urlencoded',
    })
    return printerEndpoint.post(`pstprnt`, dataToPrint).then(responseErrorCheck)
  }
}
