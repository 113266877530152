import { Component } from 'react'
import { get as _get, set as _set, isArray, cloneDeep as _cloneDeep } from 'lodash'
import { Box, Form, FormSchemaFormField, Spacer } from 'stylewhere/components'
import {
  fetchAutocompleteDefaultValues,
  FormSchema,
  FormSchemaData,
  FormSchemaField,
  getFieldValue,
  setFieldValue,
  OperationConfig,
  Storage,
  DateFormSchemaField,
} from 'stylewhere/shared'
import { showToast } from 'stylewhere/utils'
import { T, __ } from 'stylewhere/shared/i18n'
import { api, BaseResource, ResourcePage } from 'stylewhere/api'

interface Props {
  initialValues?: FormSchemaData
  schema: FormSchema
  onSubmit: (data: FormSchemaData) => void
  onCancel?: () => void
  submitText?: string
  cancelText?: string
  style?: React.CSSProperties
  disabled?: boolean
  formContext?: string
  flex?: boolean
  gridFieldColumn?: number
  operation?: OperationConfig
  formDataUpdated?: FormSchemaData
  cancelMarginTop?: number
}

interface State {
  data: FormSchemaData
  initialValues?: FormSchemaData
  loading: boolean
  schema: FormSchema
  inputCheckField?: FormSchemaField
}

/**
 * Example
 * schema={[
        { placeholder: __(T.misc.username), name: 'username', required: true },
        { placeholder: __(T.misc.password), name: 'password', type: 'password', required: true },
    ]}
 */
export class FormSchemaForm extends Component<Props, State> {
  unmounted?: boolean
  timer?: any

  state: State = {
    loading: true,
    data: {},
    schema: [],
  }

  static async preprocessFormData({ schema, initialValues }: { schema: FormSchema; initialValues: FormSchemaData }) {
    // Converti i defaultValues dei campi autocomplete
    schema = await fetchAutocompleteDefaultValues(schema)
    const dateFields = schema.filter((field) => field.type === 'date')
    for (const field of dateFields) {
      if (field.currentDateAsDefault) {
        field.defaultValue = new Date()
      }
    }

    let initialValue

    // Imposta i valori iniziali dei campi
    schema.forEach((field) => {
      initialValue = getFieldValue(initialValues, field)
      if (field.defaultValue !== undefined && initialValue === undefined) {
        setFieldValue(field.defaultValue, initialValues, field)
      }
    })

    schema.forEach((field) => {
      if(field.format) {
        initialValue = getFieldValue(initialValues, field)
        if(initialValue) {
          if(field.format.dynamicField && field.format.dynamicField != '') {
            let data = _get(initialValues, field.format.dynamicField)
            if(data) {
              const separator = field.format.separator || ''
              if(field.format.prefix) data = data + separator
              else data = separator + data
              if(initialValue && initialValue.indexOf(data) !== -1) {
                setFieldValue(initialValue.replace(data, ''), initialValues, field)
              }
            }
          } else if(field.format.character && field.format.character != '') {
            let l = 0
            let found = true
            let newValue = ''
            if(field.format.prefix) {
              l = 0 
              while(l < initialValue.length) {
                if(initialValue[l] === field.format.character && found) {
                  
                } else {
                  found = false
                  newValue += initialValue[l]
                }
                l++
              }
            } else {
              l = initialValue.length - 1
              while(l >= 0) {
                if(initialValue[l] === field.format.character && found) {
                  
                } else {
                  found = false
                  newValue = initialValue[l] + newValue
                }
                l--
              }            
            }
            setFieldValue(newValue, initialValues, field)
          }
        }
      }
    })
    return { schema, initialValues }
  }

  async componentDidMount() {
    const { schema, initialValues, operation } = this.props
    let initValues = initialValues
    if (!initValues) {
      if (operation) {
        if (operation.activeStorageForm) {
          initValues = await Storage.load('form-' + operation.type + '-' + operation.id)
        } else {
          const tmp = {}
          let obj, data, fieldName, explodeFields, tempExplodeFields
          for (let s = 0; s < schema.length; s++) {
            obj = schema[s]
            if (obj.storage) {
              fieldName = obj.name
              data = await Storage.load('form-' + operation.type + '-' + operation.id + '-' + obj.name)
              if (data) {
                if (fieldName.indexOf('.') !== -1) {
                  explodeFields = fieldName.split('.')
                  if (explodeFields.length > 1) {
                    tempExplodeFields = {}
                    if (tmp[explodeFields[0]]) {
                      tempExplodeFields = tmp[explodeFields[0]]
                    }
                    tempExplodeFields[explodeFields[1]] = data
                    tmp[explodeFields[0]] = tempExplodeFields
                  } else {
                    tmp[explodeFields[0]] = data
                  }
                } else {
                  tmp[fieldName] = data
                }
              }
            }
          }
          initValues = tmp
        }
      }
    }
    const processed = await FormSchemaForm.preprocessFormData({ schema, initialValues: initValues || {} })
    this.setState(
      {
        initialValues: processed.initialValues,
        data: processed.initialValues,
        schema: processed.schema,
        loading: false,
      },
      this.checkDirectSubmit
    )
  }

  shouldComponentUpdate = (nextProps) => {
    const { data } = this.state
    if (nextProps.formDataUpdated && JSON.stringify(nextProps.formDataUpdated) !== JSON.stringify(data)) {
      this.setState({ data: nextProps.formDataUpdated })
    }
    return true
  }

  checkDirectSubmit = () => {
    const { schema } = this.state
    if (schema.length == 1 && schema[0].directSubmit) {
      this.handleSubmit(undefined)
    }
  }

  resetForm = async () => {
    const { data } = this.state
    const { schema, operation } = this.props
    if (operation && operation.activeStorageForm) {
      await Storage.remove('form-' + operation.type + '-' + operation.id)
    }

    const tmp = data
    for (let s = 0; s < schema.length; s++) {
      if (operation && !operation.activeStorageForm) {
        await Storage.remove('form-' + operation.type + '-' + operation.id + '-' + schema[s].name)
      }
      tmp[schema[s].name] = schema[s].defaultValue || ''
    }
    this.setState({
      initialValues: tmp,
      data: tmp,
    })
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  handleSubmit = async (event: React.FormEvent | undefined) => {
    const { data, loading } = this.state
    const { onSubmit } = this.props
    if (event) event.preventDefault()
    if (loading) return
    try {
      this.setState({ loading: true })
      const errors: string[] = []
      try {
        this.state.schema.forEach((field) => {
          const value = getFieldValue(data, field)
          if (field.required && (value === undefined || value === null || value === '')) {
            errors.push(__(T.error.field_required, { label: field.label }))
            throw new Error('')
          }
        })
      } catch (error) {
        //
      }

      if (errors.length) {
        errors.forEach((error) => {
          showToast({
            title: __(T.error.error),
            description: error,
            status: 'error',
          })
        })
      } else {
        const formatData = _cloneDeep(data)
        this.state.schema.forEach((field) => {
          const value = this.formattingText(getFieldValue(formatData, field), field)
          setFieldValue(value, formatData, field)
        })
        await onSubmit(JSON.parse(JSON.stringify(formatData)))
      }

      if (!this.unmounted) this.setState({ loading: false })
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  _checkAutocompleteFields = (value: any, field: FormSchemaField) => {
    const { data, schema, initialValues } = this.state
    //aggiungere gestione lato form schema per set valori in base al change della select
    if (field.autocompleteFields) {
      let objField, val
      for (let a = 0; a < field.autocompleteFields.length; a++) {
        objField = schema.find((element) => {
          return element.name === (field && field.autocompleteFields ? field.autocompleteFields[a].name : '')
        })
        if (objField) {
          val = (value && value[field.autocompleteFields[a].field]) || objField.defaultValue || ''
          setFieldValue(val, data, objField as FormSchemaField)
        }
      }
    }

    //si controlla se esiste un altro field dipendente da quello modificato
    let schemaField
    for (let b = 0; b < schema.length; b++) {
      schemaField = schema[b]
      if (schemaField && schemaField.dependencies) {
        const objDep = schemaField.dependencies.find((element) => {
          return field.name === element.whenField
        })
        if (objDep) {
          setFieldValue('', data, schemaField as FormSchemaField)
        }
      }
    }
  }

  managerStorageSingleField = async (value: any, field: FormSchemaField) => {
    const { operation } = this.props
    if (operation) {
      if (!operation.activeStorageForm) {
        if (field.storage) {
          await Storage.save('form-' + operation.type + '-' + operation.id + '-' + field.name, value)
        }
      }
    }
  }

  handleEnter = async (value: any, field: FormSchemaField) => {
    const { data } = this.state
    setFieldValue(value, data, field)
    this.setState(data)
    if (field.submitOnEnter) {
      this.handleSubmit(undefined)
    }
  }

  formattingText = (value: string, field: FormSchemaField) => {
    if(field.format) {
      const prefix = field.format.prefix
      const separator = field.format.separator || ''
      if(field.format.dynamicField && field.format.dynamicField !== '') {
        const data = _get(this.state.data, field.format.dynamicField)
        if(data) {
          if(prefix) value = data + separator + value
          else value = value + separator + data
        }
      } else if(field.format.character && field.format.character !== '') {
        const minLength = field.format.minLength || 10
        if(value.length < minLength) {
          for(let v = 0; minLength - value.length; v++) {
            if(prefix) value = field.format.character + '' + value
            else value = value + '' + field.format.character
          }
        }
      }
    }
    return value
  }

  handleChange = async (value: any, field: FormSchemaField) => {
    const { data } = this.state
    let checkAutoSubmit = true
    setFieldValue(value, data, field)
    // per i campi input si verifica se c'è un endpoint di verifica
    if (field.autocheckEndpoint && field.autocheckEndpointField) {
      checkAutoSubmit = false
      this.setState({ inputCheckField: field, loading: true }, this.handleChangeStartTimer) //EXP, STD, VET
    } else {
      this._checkAutocompleteFields(value, field)
    }
    this.setState(data)
    field.onChange && field.onChange(value, data, (newData: any) => this.setState(newData))
    if (checkAutoSubmit && field && field.autocheckFormSubmit && !field.submitOnEnter && value !== '') {
      this.setState({ inputCheckField: field, loading: true }, this.handleChangeStartTimer)
    }
  }

  handleChangeStartTimer = () => {
    this.handleChangeStopTimer()
    this.timer = setTimeout(() => {
      this.callAutocheckEndpoint()
    }, 500)
  }

  handleChangeStopTimer = () => {
    if (!this.timer) return
    clearTimeout(this.timer)
    this.timer = undefined
  }

  callAutocheckEndpoint = async () => {
    const { data, inputCheckField } = this.state
    let checkAutoSubmit = true
    if (inputCheckField && inputCheckField.autocheckEndpoint && inputCheckField.autocheckEndpointField) {
      const value = _get(data, inputCheckField.name)
      if (value && value !== '') {
        const params = {}
        params[inputCheckField.autocheckEndpointField] = value
        const result = (
          await api.get<ResourcePage<BaseResource> | BaseResource[]>(inputCheckField.autocheckEndpoint, params)
        ).data
        if (result !== undefined && 'content' in result && result.content.length === 1) {
          this._checkAutocompleteFields(result.content[0], inputCheckField)
        } else {
          checkAutoSubmit = this._checkEmptyResultAutocheckEndpoint(undefined, inputCheckField)
          if (!checkAutoSubmit) {
            _set(data, inputCheckField.name, '')
          }
        }
      } else {
        checkAutoSubmit = this._checkEmptyResultAutocheckEndpoint(undefined, inputCheckField)
        if (!checkAutoSubmit) {
          _set(data, inputCheckField.name, '')
        }
      }
    }
    if (checkAutoSubmit) {
      this.setState({ data, loading: false }, this.checkAutoSubmit)
    } else {
      this.setState({ data, loading: false })
    }
  }

  _checkEmptyResultAutocheckEndpoint = (value: any, field: FormSchemaField) => {
    if (!field.autocheckEndpointBlockIfEmpty) {
      this._checkAutocompleteFields(value, field)
      return true
    }
    showToast({
      title: __(T.error.error),
      description: __(T.error.value_not_valid),
      status: 'error',
    })
    return false
  }

  checkAutoSubmit = () => {
    const { inputCheckField } = this.state
    if (inputCheckField && inputCheckField.autocheckFormSubmit) {
      this.handleSubmit(undefined)
    }
  }

  triggerDependency = (is: string, field: FormSchemaField, additionalValues: any, hide: boolean) => {
    const { data } = this.state
    if (is === 'disabled') {
      field.disabled = true
      // Svuota il campo quando lo disabiliti
      setFieldValue(null, data, field)
    } else if (is === 'enabled') {
      field.disabled = false
    } else if (is === 'emptied') {
      setFieldValue(null, data, field)
    } else if (is === 'filledWithValue') {
      setFieldValue(additionalValues, data, field)
    } else if (is === 'hidden') {
      hide = true
    } else if (is === 'visible') {
      hide = false
    } else if (is === 'optional') {
      field.required = false
    } else if (is === 'required') {
      field.required = true
    } else if (is === 'endpointReplaced' && field.type === 'autocomplete') {
      /**
       * Sostituisce parti dinamiche dell'endpoint con i valori del campo whenField corrispondenti
       * Quindi per esempio:
       * {
       *   name: 'foo',
       *   type: 'text',
       *  },
       * {
       *   name: 'bar',
       *   type: 'autocomplete',
       *   endpoint: 'some/url/?code=:fooCode',
       *   dependencies: [
       *     {
       *       is: 'endpointReplaced',
       *       endpointReplaced: { fooCode: '' },
       *       whenField: 'foo',
       *       condition: 'isFilled',
       *     },
       *   ],
       * }
       * Se il valore del campo foo è "ciao", l'endpoint del campo bar diventa "some/url/?code=ciao"
       * (Se fooCode è una stringa vuota, prende l'intero valore del campo. Se invece è una stringa,
       * tratta il campo come un oggetto e prende il valore nidificato con la funzione lodash _.get)
       */
      Object.keys(additionalValues.replacements).forEach((token) => {
        const replacement = additionalValues.replacements[token]
        if (!field.rawEndpoint) field.rawEndpoint = field.endpoint
        if (isArray(additionalValues.whenFieldValue)) {
          let additionalParams = ''
          additionalValues.whenFieldValue.forEach((whenFieldValue, index) => {
            const _rep = replacement ? _get(whenFieldValue, replacement) : additionalValues.whenFieldValue
            additionalParams += `${index > 0 ? `&${token}=` : ''}${_rep}`
          })
          field.endpoint = field.rawEndpoint.replaceAll(`:${token}`, additionalParams)
        } else {
          field.endpoint = field.rawEndpoint.replaceAll(
            `:${token}`,
            replacement ? _get(additionalValues.whenFieldValue, replacement) : additionalValues.whenFieldValue
          )
        }
      })
    }
  }

  negateIs = (is: string) => {
    if (is === 'disabled') return 'enabled'
    if (is === 'enabled') return 'disabled'
    if (is === 'hidden') return 'visible'
    if (is === 'visible') return 'hidden'
    if (is === 'optional') return 'required'
    if (is === 'required') return 'optional'
    return false
  }

  isAutocheckFormSubmit = () => {
    const { schema } = this.state
    if (schema) {
      const autoCheck = schema.find((field) => field.autocheckFormSubmit)
      return autoCheck
    }
    return false
  }

  isColumnsForm = (fields) => {
    const { gridFieldColumn } = this.props
    if (gridFieldColumn === 2) {
      return fields.filter((field) => field.type !== 'hidden').length > 3
    }
    return false
  }

  render() {
    const { submitText, style, onCancel, formContext, disabled, flex, operation, cancelText, cancelMarginTop } =
      this.props
    const { data, initialValues, loading, schema } = this.state
    const hide = false
    if (!data || !initialValues) return null
    const fields = schema.filter((field) => !field.hide)
    const columns = this.isColumnsForm(fields)
    const isAutocheckFormSubmit = this.isAutocheckFormSubmit()
    return (
      <Form
        columns={columns}
        submitText={isAutocheckFormSubmit ? undefined : submitText}
        cancelText={cancelText}
        cancelMarginTop={cancelMarginTop || 30}
        style={style}
        onCancel={onCancel}
        onSubmit={this.handleSubmit}
        loading={loading}
        flex={flex}
      >
        {fields.map((field, index) => {
          // Dipendenze tra campi (per ora ne gestiamo solo una)
          if (field.dependencies && field.dependencies.length) {
            field.dependencies.forEach((dependency) => {
              const whenField = fields.find(({ name }) => name === dependency.whenField)
              if (whenField) {
                const whenFieldValue = getFieldValue(data, whenField)
                const isEmpty = (v) => v === '' || v === null || v === undefined

                // Parametri addizionali che cambiano a seconda del tipo di effetto
                let additionalOptions
                if (dependency.is === 'filledWithValue') additionalOptions = dependency.filledWithValue
                else if (dependency.is === 'endpointReplaced')
                  additionalOptions = {
                    whenFieldValue,
                    replacements: dependency.endpointReplaced,
                  }

                if (
                  (dependency.condition === 'hasValue' && whenFieldValue === dependency.conditionValue) ||
                  (dependency.condition === 'isEmpty' && isEmpty(whenFieldValue)) ||
                  (dependency.condition === 'isFilled' && !isEmpty(whenFieldValue))
                ) {
                  // Dipendenza triggerata
                  this.triggerDependency(dependency.is, field, additionalOptions, hide)
                } else {
                  // Dipendenza non triggerata (triggera l'effetto opposto)
                  const negated = this.negateIs(dependency.is)
                  if (negated) {
                    this.triggerDependency(negated, field, additionalOptions, hide)
                  }
                }
              }
            })
          }

          return (
            <>
              <Box key={field.name} hidden={field.type === 'hidden'}>
                {!hide && (
                  <FormSchemaFormField
                    field={field}
                    index={index}
                    value={getFieldValue(data, field)}
                    defaultValue={getFieldValue(initialValues, field)}
                    disabled={disabled}
                    onChange={(v) => this.handleChange(v, field)}
                    onEnter={(v) => this.handleEnter(v, field)}
                    formContext={formContext}
                    operation={operation}
                  />
                )}
                <Spacer />
              </Box>
              {field.addEmptyField && <Box />}
            </>
          )
        })}
      </Form>
    )
  }
}
