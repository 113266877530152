import { merge as _merge } from 'lodash'
import it from 'core/translations/it'

export default _merge(it, {
  titles: {
    outbound_bthp: 'Outbound Certificato BTHP',
    sorting_details: 'Dettagli ventilazione',
  },
  simple_parcel_list: {
    to_read: 'Non letto',
    ok: 'Ok',
    force: 'Forza',
    force_parcel: 'Forza collo {{code}}',
    force_parcel_desc: 'Inserisci il PIN utente per forzare il collo',
    forced: 'Forzato',
    unexpected: 'Inatteso',
    remove: 'Rimuovi',
    invalid_pin: 'PIN utente non valido',
  },
  create_parcel_gate: {
    width: 'Larghezza',
    height: 'Altezza',
    depth: 'Profondità',
    tare: 'Tara',
    description: 'Descrizione',
  },
  misc: {
    sorted_quantity: 'Qt ventilata',
    sorted_date: 'Data ventilazione',
  },
  error: {
    sorting_already_read: 'Articolo con seriale {{serial}} già ventilato nel gruppo {{group}}',
    sorting_no_group: 'Articolo da non ventilare',
    sorting_not_in_zone: "Articolo con seriale {{serial}} da non ventilare. L'articolo è nella zona {{zone}}",
  },
  serverError: {
    WRONG_ITEM_SUPPLIER: 'Rilevati item non associati al fornitore dichiarato.',
    QUANTITY_MISMATCH_ERROR: 'Quantità inserite non corrispondenti alle quantità rilevate.',
    ZONE_MISMATCH_ERROR: 'Zona inserita non corrispondente a quella rilevata.',
  },
  custom: {
    barcode: 'Barcode BC',
    barcodeBCTrim: 'Barcode BC trim',
    barcodeJDE: 'Barcode JDE',
    barcodeROQ: 'Barcode ROQ',
    barcodeROQTrim: 'Barcode ROQ trim',
    class: 'Classe',
    department: 'Dipartimento',
    dimensionSystem: 'Classe personalizzata',
    ean: 'EAN',
    eanIdPadre: 'EAN id padre',
    itemId: 'Item Id',
    stagioneROQ: 'Stagione ROQ',
    subclass: 'Sottoclasse',
    subdepartment: 'Sottodipartimento',
    variantType: 'Tipologia',
    rfidEnabled: 'RFID abilitato',
    stato: 'Stato',
    ricondizionamentoFornitore: 'Fornitore ricondizionamento',
    codiceShelf: 'Codice scaffale',
    montante: 'Montante',
    ripiano: 'Ripiano',
    scaffale: 'Scaffale',
  },
})
