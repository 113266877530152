import { Box, BoxProps, Spacer } from 'stylewhere/components'
import { FormLabel, Switch as ChakraSwitch } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useState } from 'react'

interface Props {
  name: string
  id?: string
  label: string
  labelPosition?: 'left' | 'right'
  check?: boolean
  onChange: (checked: boolean) => void
  wrapperProps?: BoxProps
  disabled?: boolean
  boxed?: boolean
}

export const Switch: React.FC<Props> = ({
  name,
  id,
  label,
  labelPosition = 'right',
  check,
  onChange,
  wrapperProps,
  boxed = false,
  disabled = false,
}) => {
  const [checked, setChecked] = useState(check ?? false)

  const onCheck = (e?: React.ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault()
    if (!disabled) {
      setChecked(!checked)
      onChange(!checked)
    }
  }

  const Label = (
    <FormLabel htmlFor={name} mb="0" cursor="pointer" flex="1" color={disabled ? '#eeeeee' : '#666666'}>
      {label}
    </FormLabel>
  )
  const CSwitch = (
    <ChakraSwitch onChange={onCheck} isChecked={checked} id={id ?? name} name={name} size="lg" disabled={disabled} />
  )

  return (
    <SwitchBox onClick={onCheck} boxed={boxed} row vcenter {...wrapperProps}>
      {labelPosition === 'left' && (
        <>
          {Label}
          {CSwitch}
        </>
      )}
      {labelPosition === 'right' && (
        <>
          {CSwitch}
          <Spacer />
          {Label}
        </>
      )}
    </SwitchBox>
  )
}

const SwitchBox = styled(Box)<{ boxed: boolean }>`
  ${({ boxed }) =>
    boxed &&
    `
      background: #FBFBFB;
      border: 2px solid #DCDCDC;
      width: fit-content;
      padding: 16px 12px;  
      border-radius: 10px;
      font-size: 16px;
    `}
`
