import Dashboard from './Dashboard'
import TagInfo from './TagInfo/TagInfo'
import TransferToZoneStart from './TransferToZone/TransferToZoneStart'
import TransferToZoneReading from './TransferToZone/TransferToZoneReading'
import SimpleItemListStart from './SimpleItemList/SimpleItemListStart'
import SimpleItemListReading from './SimpleItemList/SimpleItemListReading'
import SimpleParcelListStart from './SimpleParcelList/SimpleParcelListStart'
import SimpleParcelListReading from './SimpleParcelList/SimpleParcelListReading'
import ShippingStart from './Shipping/ShippingStart'
import ShippingReading from './Shipping/ShippingReading'
import SortingList from './Sorting/SortingList'
import SortingStart from './Sorting/SortingStart'
import SortingReading from './Sorting/SortingReading'
import Login from './Login'
import ConnectionError from './ConnectionError'
import EncodingEncode from './Encoding/EncodingEncode'
import EncodingVerify from './Encoding/EncodingVerify'
import InventoryList from './Inventory/InventoryList'
import InventoryRead from './Inventory/InventoryRead'

import SimpleItemSessionListStart from './SimpleItemSessionList/SimpleItemSessionListStart'
import SimpleItemSessionListReading from './SimpleItemSessionList/SimpleItemSessionListReading'
import CountTBoxUbicazioniStart from './CountTBoxUbicazioni/CountTBoxUbicazioniStart'
import CountTBoxUbicazioniReading from './CountTBoxUbicazioni/CountTBoxUbicazioniReading'
import ImbustatriceStart from './Encoding/Imbustatrice/ImbustatriceStart'
import ImbustatriceEncode from './Encoding/Imbustatrice/ImbustatriceEncode'
import ConveyorStart from './Conveyor/ConveyorStart'
import ConveyorReading from './Conveyor/ConveyorReading'

import EncodingInboundSapStart from './EncodingInboundSAP/EncodingInboundSapStart'
import EncodingInboundSapEncode from './EncodingInboundSAP/EncodingInboundSapEncode'
import EncodingInboundSapView from './EncodingInboundSAP/EncodingInboundSapView'

import EncodingOutboundSapStart from './EncodingOutboundSap/EncodingOutboundSapStart'
import EncodingOutboundSapEncode from './EncodingOutboundSap/EncodingOutboundSapEncode'

import CartonizationStart from './Shipping/Cartonization/CartonizationStart'
import CartonizationReading from './Shipping/Cartonization/CartonizationReading'

import Utilities from './Utilities/Utilities'
import WriteTag from './Utilities/WriteTag'

export const Routes = {
  '/': { component: Dashboard },
  '/tag-info/:opCode': { component: TagInfo },
  '/transfer-to-zone/conveyor/:opCode': { component: ConveyorStart },
  '/transfer-to-zone/conveyor/:opCode/reading': { component: ConveyorReading },
  '/transfer-to-zone/:opCode': { component: TransferToZoneStart },
  '/transfer-to-zone/:opCode/reading': { component: TransferToZoneReading },
  '/simple-item-list/:opCode': { component: SimpleItemListStart },
  '/simple-item-list/:opCode/reading': { component: SimpleItemListReading },
  '/simple-item-session-list/encodingInboundSap/:opCode': { component: EncodingInboundSapStart },
  '/simple-item-session-list/encodingInboundSap/:opCode/encode': { component: EncodingInboundSapEncode },
  '/simple-item-session-list/encodingInboundSap/:opCode/view': { component: EncodingInboundSapView },
  '/simple-item-session-list/countTBoxUbicazioni/:opCode': { component: CountTBoxUbicazioniStart },
  '/simple-item-session-list/countTBoxUbicazioni/:opCode/reading': { component: CountTBoxUbicazioniReading },
  '/simple-item-session-list/:opCode': { component: SimpleItemSessionListStart },
  '/simple-item-session-list/:opCode/reading': { component: SimpleItemSessionListReading },
  '/simple-parcel-list/:opCode': { component: SimpleParcelListStart },
  '/simple-parcel-list/:opCode/reading': { component: SimpleParcelListReading },
  '/shipping/cartonization/:opCode': { component: CartonizationStart },
  '/shipping/cartonization/:opCode/reading': { component: CartonizationReading },
  '/shipping/:opCode': { component: ShippingStart },
  '/shipping/:opCode/reading': { component: ShippingReading },
  '/sorting/:opCode': { component: SortingList },
  '/sorting/:opCode/start/:code': { component: SortingStart },
  '/sorting/:opCode/start': { component: SortingStart },
  '/sorting/:opCode/reading': { component: SortingReading },
  '/encoding/encodingOutboundSap/:opCode': { component: EncodingOutboundSapStart },
  '/encoding/encodingOutboundSap/:opCode/encode': { component: EncodingOutboundSapEncode },
  '/encoding/imbustatrice/:opCode': { component: ImbustatriceStart },
  '/encoding/imbustatrice/:opCode/encode': { component: ImbustatriceEncode },
  '/encoding/:opCode': { component: EncodingEncode },
  '/encoding/:opCode/verify': { component: EncodingVerify },
  '/inventory/:opCode': { component: InventoryList },
  '/inventory/:opCode/start/:id': { component: InventoryRead },
  '/utilities': { component: Utilities, public: true, private: true },
  '/utilities/write-tag': { component: WriteTag, public: true, private: true },
  '/login': { component: Login, public: true },
  '/connection-error': { component: ConnectionError, public: true, private: true },
}
