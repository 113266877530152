import { useState, useRef, useEffect } from 'react'
import ReactSelect, { components, OptionsType } from 'react-select'
import AsyncSelect from 'react-select/async'
import { useDebouncedCallback } from 'use-debounce'
import { Box, Icons, Modal, Button } from 'stylewhere/components'
import { T, __ } from 'stylewhere/i18n'
import { getField } from 'stylewhere/utils'

type OptionConfig = { label: string; value: string; secondaryLabel?: string; concatSecondaryLabel?: boolean }

const maxFontSize = 22
const minFontSize = 16
const maxCharacters = 22
const decrement = 0.3

interface SelectProps {
  placeholder?: React.ReactNode
  defaultValue?: any
  containerStyle?: React.CSSProperties
  controlStyle?: React.CSSProperties
  disabled?: boolean
  options?: any[]
  asyncOptions?: (inputValue: string, callback: (options: OptionsType<Record<string, any>>) => void) => void
  debounce?: boolean
  config: OptionConfig
  borderColor?: string
  onSelect: (item?: any) => void
  transparent?: boolean
  multiple?: boolean
  value?: any
  useButton?: boolean
}

const Option: typeof components.Option = ({ children, ...props }) => (
  <components.Option {...props}>
    <Box flex row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <span
        style={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '18px' }}
        title={children?.toString()}
      >
        {children}
      </span>
      {props.isSelected && <Icons.CheckFilled />}
    </Box>
  </components.Option>
)

const ClearIndicator: typeof components.ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <Icons.Close />
  </components.ClearIndicator>
)

export const Select: React.FC<SelectProps> = ({
  onSelect,
  config,
  placeholder,
  defaultValue,
  containerStyle,
  controlStyle,
  options,
  asyncOptions,
  debounce = true,
  borderColor = '#D2D2D2',
  disabled,
  transparent,
  multiple = false,
  value,
  useButton,
}) => {
  const selectRef = useRef<ReactSelect<any>>(null)
  const [open, setOpen] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const debouncedAsyncOptions = useDebouncedCallback(asyncOptions ?? (() => {}), 400, { leading: true })

  useEffect(() => {
    setFirstRender(false)
  }, [])

  const styles = {
    container: (base) => ({
      ...base,
      border: 0,
      ...containerStyle,
    }),
    control: (base, state) => ({
      ...base,
      height: 65,
      fontSize: getFontSize(state.label, decrement),
      paddingLeft: 7,
      backgroundColor: transparent ? 'transparent' : '#ffffff',
      boxShadow: state.isFocused ? '0 0 0 3px rgba(47, 128, 237, 0.5)' : 'none',
      borderWidth: 2,
      borderColor: state.isFocused ? '#2f80ed' : borderColor,
      borderRadius: 10,
      '&:hover': {
        borderColor: state.isFocused ? '#2f80ed' : borderColor,
      },
      ...controlStyle,
    }),
    input: () => ({
      color: '#333333',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#666666',
    }),
    menu: (base) => ({
      ...base,
      position: 'relative',
      border: 0,
      boxShadow: 0,
      marginTop: '1rem',
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 350,
    }),
    option: (base, state) => {
      return {
        ...base,
        fontWeight: 500,
        fontSize: getFontSize(state.label, decrement),
        lineHeight: '48px',
        borderRadius: 10,
        height: 70,
        color: '#333333',
        borderWidth: 2,
        borderColor: state.isSelected ? '#000000' : '#F5F5F5',
        padding: '10px 20px',
        backgroundColor: '#F5F5F5',
        marginBottom: '1rem',
        cursor: 'pointer',
      }
    },
    singleValue: (base) => ({
      ...base,
      color: 'black',
      marginLeft: 0,
      marginRight: 0,
      fontSize: '18px',
    }),
    indicatorSeparator: () => ({}),
    indicatorsContainer: (base) => ({
      ...base,
      paddingRight: 10,
      '& svg': { width: 26, height: 26 },
    }),
    clearIndicator: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
  }

  const closeModal = () => {
    setOpen(false)
  }

  const onChange = (selectedItems) => {
    if (selectedItems?.length === 0) {
      onSelect(undefined)
    } else {
      onSelect(selectedItems)
    }
    !multiple && closeModal()
  }

  const getOptionLabel = (option: Record<string, any>) => {
    try {
      const label = getField(option, config.label)
      if (label) {
        if (config.concatSecondaryLabel && config.secondaryLabel) {
          return label + ' - ' + getField(option, config.secondaryLabel)
        }
        return label
      }
      return config.secondaryLabel ? getField(option, config.secondaryLabel) : ''
    } catch (error) {
      return ''
    }
  }

  const getFontSize = (_value?: any, _d = 0.5) => {
    const label: string = _value ?? getOptionLabel(value) ?? ''
    if (label.length <= maxCharacters) return maxFontSize
    const calcFontSize = maxFontSize - (label.length - maxCharacters) * _d
    if (calcFontSize > minFontSize) return calcFontSize
    return minFontSize
  }

  const modalSelectProps = {
    isClearable: true,
    isMulti: multiple,
    isSearchable: true,
    styles,
    getOptionLabel,
    getOptionValue: (option) => getField(option, config.value),
    defaultValue: firstRender ? defaultValue : value,
    onChange,
    value,
    autoFocus: true,
    menuIsOpen: true,
    placeholder: __(T.misc.select_search),
    components: {
      ClearIndicator,
      DropdownIndicator: null,
      Option,
    },
    noOptionsMessage: () => __(T.misc.no_result),
  }

  const selectStyle = {
    ...styles,
    container: (base, state) => ({
      ...base,
      border: `2px solid ${state.isDisabled ? '#eeeeee' : borderColor}`,
      borderRadius: 10,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: '#eeeeee',
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: 6,
      backgroundColor: '#eeeeee',
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: 0,
      borderRadius: 6,
      backgroundColor: '#eeeeee',
    }),
    multiValueRemove: (base) => ({
      ...base,
      borderRadius: 6,
      backgroundColor: '#eeeeee',
    }),
    control: (base, state) => ({
      ...base,
      paddingTop: multiple && state.hasValue ? 15 : 0,
      minHeight: 61,
      fontSize: getFontSize(),
      paddingLeft: state.hasValue ? 5 : 7,
      backgroundColor: transparent ? 'transparent' : '#ffffff',
      boxShadow: 'none',
      borderWidth: 0,
      borderRadius: 10,
      cursor: 'pointer',
      ...controlStyle,
    }),
    placeholder: (base, state) => ({
      ...base,
      color: state.isDisabled ? '#eeeeee' : '#666666',
    }),
    singleValue: (base, state) => ({
      ...base,
      marginTop: state.hasValue ? 10 : 0,
      marginLeft: 0,
      marginRight: 0,
      fontSize: '18px',
    }),
    input: (base, state) => {
      return {
        ...base,
        marginLeft: 0,
        marginRight: 0,
        caretColor: 'transparent',
        '& input': {
          cursor: 'pointer',
          caretColor: 'transparent',
        },
      }
    },
  }

  return (
    <>
      <Modal visible={open} onClose={closeModal} title={placeholder} size="xl">
        <div style={{ minHeight: 435 }}>
          {asyncOptions && (
            <AsyncSelect
              {...modalSelectProps}
              loadOptions={debounce ? debouncedAsyncOptions : asyncOptions}
              defaultOptions
              cacheOptions
              loadingMessage={() => null}
              options={options}
              onMenuClose={() => {}}
            />
          )}
          {!asyncOptions && <ReactSelect {...modalSelectProps} options={options} />}
        </div>
      </Modal>
      {useButton ? (
        <Button
          title={__(T.misc.change)}
          onClick={() => {
            setOpen(true)
            return true
          }}
          variant="secondary"
        />
      ) : (
        <ReactSelect
          ref={selectRef}
          styles={selectStyle}
          placeholder={placeholder}
          value={firstRender ? defaultValue : value}
          options={value ? [value] : []}
          getOptionLabel={getOptionLabel}
          onMenuOpen={() => setOpen(true)}
          onChange={(s, d) =>
            d.action === 'remove-value'
              ? onChange((value ?? []).filter((v) => getOptionLabel(d.removedValue) !== getOptionLabel(v)))
              : onChange([])
          }
          components={value ? { ClearIndicator, DropdownIndicator: null } : { ClearIndicator }}
          isDisabled={disabled}
          isMulti={multiple}
        />
      )}
    </>
  )
}
