import { useParams } from 'react-router-dom'
import { AntennaButton, Box, EmptyListMessage, FormSchemaForm, Icons, Page } from 'stylewhere/components'
import { Router, RemoteOperation, FormSchemaData, FormSchema, OperationConfig, Storage } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/i18n'
import type { Routes } from 'stylewhere/pages'
import { get as _get } from 'lodash'
import { Extensions } from 'core/extensions/Extensions'
import { api, BaseResource, ResourcePage } from 'stylewhere/api'

type Props<OC extends OperationConfig> = {
  submitPath: Routes
  onSubmit?: (formData: FormSchemaData, operation: OC, schema: FormSchema) => void
  startWithRfidSearch?: boolean
  extensions: typeof Extensions
  formSchemaAdditionalData?: any
  backPath?: Routes
  initialValues?: any
  loading?: boolean
  customCode?: string
  formDataUpdated?: FormSchemaData
  formRowField?: boolean
}

/**
 * *Ricorda*: Richiede un route param `opCode` o con un customCode passato in props
 */
export const OperationStart = <OC extends OperationConfig>({
  submitPath,
  backPath,
  onSubmit,
  extensions,
  startWithRfidSearch,
  formSchemaAdditionalData,
  initialValues,
  loading,
  customCode,
  formDataUpdated,
  formRowField,
}: Props<OC>) => {
  const { opCode } = useParams<{ opCode: string }>()
  const operation = RemoteOperation.getOperationConfig<OC>(customCode || opCode)
  const schema = extensions.formSchema(operation, formSchemaAdditionalData)

  const submit = async (formData: FormSchemaData) => {
    const validForm = await extensions.formDataIsValid(formData, operation, schema)
    if (!validForm) return

    //salvataggio local storage tutti i dati inseriti nel form
    if (operation.activeStorageForm) {
      await Storage.save('form-' + operation.type + '-' + operation.id, formData)
    } else {
      //si salvano i singoli campi nello schema
      let obj, value
      for (let s = 0; s < schema.length; s++) {
        obj = schema[s]
        value = _get(formData, obj.name)
        if (obj.storage && value) {
          await Storage.save('form-' + operation.type + '-' + operation.id + '-' + obj.name, value)
        }
      }
    }
    if (onSubmit) {
      await onSubmit(formData, operation, schema)
    } else {
      Router.navigate(submitPath, { opCode: customCode || opCode }, { state: { formData } })
    }
  }

  // Se non ci sono campi, fai un redirect immediato alla fase di lettura
  if (schema.length === 0) {
    submit({})
    return null
  }

  return (
    <Page
      title={operation.description}
      onBackPress={() => (backPath ? Router.navigate(backPath, { opCode: operation.code }) : Router.navigate('/'))}
      enableEmulation={!!startWithRfidSearch}
    >
      <Page.Content notBoxed>
        {!!loading && (
          <Box flex center>
            <Icons.Loader1 />
          </Box>
        )}
        {!loading && (
          <Box flex>
            {!!startWithRfidSearch && (
              <Box flex center style={{ backgroundColor: 'white', borderRadius: 15 }}>
                <Box center>
                  <EmptyListMessage center style={{ marginBottom: 20 }}>
                    {__(T.messages.press_start_to_read)}
                  </EmptyListMessage>
                  <AntennaButton />
                </Box>
              </Box>
            )}
            {!startWithRfidSearch && (
              <Box flex center>
                <FormSchemaForm
                  initialValues={initialValues}
                  schema={schema}
                  submitText={__(T.misc.next)}
                  onSubmit={submit}
                  formContext="start"
                  gridFieldColumn={operation.formSchemaSingleRow || formRowField ? 1 : 2}
                  operation={operation}
                  formDataUpdated={formDataUpdated}
                />
              </Box>
            )}
          </Box>
        )}
      </Page.Content>
    </Page>
  )
}
