import styled from '@emotion/styled'
import { Box, TableStructure, Modal, Image, Icons, Button, GroupedItemList } from 'stylewhere/components'
import { DecodedItem, Products, TmrProduct } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { OperationConfig, AppStore } from 'stylewhere/shared'
import { Component } from 'react'

interface Props {
  isOpen: boolean
  product: TmrProduct
  decodedItems: DecodedItem[]
  operation?: OperationConfig
  onClose: () => void
  onRemoveItem?: (item: DecodedItem) => void
}

const ProductDetailsRow: React.FC<{ label: string; value?: string; last?: boolean }> = ({ label, value, last }) => {
  return (
    <Box
      flex
      row
      justify="space-between"
      style={{ borderBottom: last ? undefined : '1px solid #e0e0e0', lineHeight: '33px' }}
    >
      <Box style={{ fontSize: 14, marginRight: 5 }}>{label}</Box>
      <Box style={{ fontWeight: 700, wordWrap: 'break-word', flex: 1, textAlign: 'end' }}>{value ?? '--'}</Box>
    </Box>
  )
}

export class ProductItemsModal extends Component<Props> {
  structure: TableStructure<DecodedItem>[] = [
    {
      key: 'identifiers',
      label: __(T.misc.identifiers),
      customRender: ({ item }) =>
        item?.identifiers.map((identifier) => <div key={identifier.id}>{identifier.code}</div>),
      width: 300,
    },
    {
      key: 'zone',
      label: __(T.misc.zone),
      customValue: ({ item }) =>
        `${item?.zone?.place.description ?? item?.zone?.place.code ?? '--'} - ${
          item?.zone?.description ?? item?.zone?.code ?? '--'
        }`,
      flex: 1,
    },
  ]

  getProductCode = (product) => product.code

  render() {
    const { isOpen, onClose, product, decodedItems, onRemoveItem, operation } = this.props
    if (onRemoveItem) {
      this.structure.push({
        key: 'delete',
        customRender: (item) => (
          <Button onClick={() => onRemoveItem(item)} variant="secondary" size="small" circle>
            <Icons.Delete width={24} height={24} />
          </Button>
        ),
        width: 78,
      })
    }

    return (
      <Modal visible={isOpen ?? true} onClose={onClose} size="6xl">
        <Title>{__(T.misc.product_items)}</Title>
        <Box flex row>
          <Box pr={25} style={{ maxWidth: '30%', minWidth: '30%' }}>
            {AppStore.getShowProductImage() && (
              <Image src={Products.getImageUrl(product)} width={200} height={200} style={{ marginBottom: 16 }} />
            )}
            <ProductTitle>{this.getProductCode(product)}</ProductTitle>
            <div>{product.description}</div>
            <Box mt={16}>
              <ProductDetailsRow
                label={__(T.misc.color)}
                value={product.color?.description ?? product.color?.value ?? 'n/a'}
              />
              <ProductDetailsRow label={__(T.misc.model)} value={product.style?.value ?? 'n/a'} />
              <ProductDetailsRow label={__(T.misc.typology)} value={product.attributes?.variantType?.value ?? 'n/a'} />
              <ProductDetailsRow
                label={__(T.misc.size)}
                value={product.size?.description ?? product.size?.value ?? 'n/a'}
                last
              />
            </Box>
          </Box>
          <GroupedItemList hideProductInfo operation={operation} rows={decodedItems} disabledCounter />
        </Box>
      </Modal>
    )
  }
}

const Title = styled.h2`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 25px;
`

const ProductTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
`
