import { Component } from 'react'
import { Box, ProductInfoBox, Spacer, Button, KeyValueRow, ProductInfoBoxWam } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'
import { TmrProduct, EncodingCounters, ProductInfoField } from 'stylewhere/api'

interface Props {
  product?: TmrProduct
  clear: any
  counters?: EncodingCounters
  fields?: ProductInfoField[]
  productClose?: any
  initialType?: string
  operationId?: string
  wam?: string
}

export class EncodingProduct extends Component<Props> {
  render() {
    const { product, clear, counters, fields, productClose, initialType, operationId, wam } = this.props
    return (
      <Box flex justify={'space-between'}>
        <Box mb={10}>
          <ProductInfoBox product={product} fields={fields} onCloseClick={productClose} />
          <ProductInfoBoxWam initialType={initialType} wam={wam} operationId={operationId} />
          {counters && (
            <>
              <Spacer />
              <KeyValueRow>
                <KeyValueRow
                  variant="raw"
                  value={`${counters?.order.pending + counters?.order.encoded} / ${counters?.order.expected}`}
                  label={__(T.misc.item_order)}
                />
                <Box height={60} width={2} bgGrey />
                <KeyValueRow
                  variant="raw"
                  value={`${counters?.row.pending + counters?.row.encoded} / ${counters?.row.expected}`}
                  label={__(T.misc.item_row)}
                />
              </KeyValueRow>
              <Spacer />
            </>
          )}
        </Box>
        <Button variant="default" title={__(T.misc.clear)} onClick={clear} />
      </Box>
    )
  }
}
